<template>
  <div>

    <div class='d-flex'>
      <v-btn-toggle dense mandatory v-model="fy" >
        <v-btn plain style="text-transform: unset;"
          v-for="fy in fys[person]"
          :key="fy"
          :value="fy"
        >
          {{ fy }}
        </v-btn>
      </v-btn-toggle>
      <v-spacer></v-spacer>
      <v-btn-toggle dense mandatory v-model="person" >
        <v-btn plain style="text-transform: unset;"
          v-for="person in persons"
          :key="person"
          :value="person"
        >
          {{ person }}
        </v-btn>
      </v-btn-toggle>
    </div>

    <Table api="/api/itr" :params="apiParams[17]" :class="cssClasses[17]" :rowClassFn="rowClassFn" />
    <Table api="/api/itr" :params="apiParams[16]" :class="cssClasses[16]" :rowClassFn="rowClassFn" />
    <Table api="/api/itr" :params="apiParams[15]" :class="cssClasses[15]" :rowClassFn="rowClassFn" />
    <Table api="/api/itr" :params="apiParams[14]" :class="cssClasses[14]" :rowClassFn="rowClassFn" />
    <Table api="/api/itr" :params="apiParams[13]" :class="cssClasses[13]" :rowClassFn="rowClassFn" />
    <Table api="/api/itr" :params="apiParams[12]" :class="cssClasses[12]" :rowClassFn="rowClassFn" />
    <Table api="/api/itr" :params="apiParams[11]" :class="cssClasses[11]" :rowClassFn="rowClassFn" />
    <Table api="/api/itr" :params="apiParams[10]" :class="cssClasses[10]" :rowClassFn="rowClassFn" />
    <Table api="/api/itr" :params="apiParams[9]" :class="cssClasses[9]" :rowClassFn="rowClassFn" />
    <Table api="/api/itr" :params="apiParams[8]" :class="cssClasses[8]" :rowClassFn="rowClassFn" />
    <Table api="/api/itr" :params="apiParams[7]" :class="cssClasses[7]" :rowClassFn="rowClassFn" />
    <Table api="/api/itr" :params="apiParams[6]" :class="cssClasses[6]" :rowClassFn="rowClassFn" />
    <Table api="/api/itr" :params="apiParams[5]" :class="cssClasses[5]" :rowClassFn="rowClassFn" />
    <Table api="/api/itr" :params="apiParams[4]" :class="cssClasses[4]" :rowClassFn="rowClassFn" />
    <Table api="/api/itr" :params="apiParams[3]" :class="cssClasses[3]" :rowClassFn="rowClassFn" />
    <Table api="/api/itr" :params="apiParams[2]" :class="cssClasses[2]" :rowClassFn="rowClassFn" />
    <Table api="/api/itr" :params="apiParams[1]" :class="cssClasses[1]" :rowClassFn="rowClassFn" />
    <Table api="/api/itr" :params="apiParams[0]" :class="cssClasses[0]" :rowClassFn="rowClassFn" />

  </div>
</template>

<script>
import util from "@/util.js";
import Table from "../components/Table";
export default {
  components: {
    Table
  },
  data() {
    return {
      person: 'Prashant',
      persons: [ 'Prashant', 'Disha', 'Shivendra', 'Shiksha', 'Dad' ],
      fy: util.currFy,
      fys: {
        'Prashant'  : [ 'fy19', 'fy20', 'fy21', 'fy22', 'fy23' ],
        'Disha'     : [ 'fy19', 'fy20', 'fy21', 'fy22', 'fy23' ],
        'Shivendra' : [ 'fy19', 'fy20', 'fy21', 'fy22', 'fy23' ],
        'Shiksha'   : [ 'fy19', 'fy20', 'fy21', 'fy22', 'fy23' ],
        'Dad'       : [ 'fy19', 'fy20', 'fy21', 'fy22', 'fy23' ],
      },
      apiParams: [
        { person:'Prashant', fy:util.currFy, section:'heads'                },
        { person:'Prashant', fy:util.currFy, section:'paid'                 },
        { person:'Prashant', fy:util.currFy, section:'Types'                },
        { person:'Prashant', fy:util.currFy, section:'Cumulative'           },
        { person:'Prashant', fy:util.currFy, section:'Exemptions'           },
        { person:'Prashant', fy:util.currFy, section:'After Exemptions'     },
        { person:'Prashant', fy:util.currFy, section:'Adjustments'          },
        { person:'Prashant', fy:util.currFy, section:'After Adjustments'    },
        { person:'Prashant', fy:util.currFy, section:'Set-Offs'             },
        { person:'Prashant', fy:util.currFy, section:'After Set-Offs'       },
        { person:'Prashant', fy:util.currFy, section:'Carry Forwards'       },
        { person:'Prashant', fy:util.currFy, section:'After Carry Forwards' },
        { person:'Prashant', fy:util.currFy, section:'Deductions'           },
        { person:'Prashant', fy:util.currFy, section:'After Deductions'     },
        { person:'Prashant', fy:util.currFy, section:'Waivers'              },
        { person:'Prashant', fy:util.currFy, section:'After Waivers'        },
        { person:'Prashant', fy:util.currFy, section:'Tax'                  },
        { person:'Prashant', fy:util.currFy, section:'Liability'            },
      ],
      cssClasses: [
        'mt-2 green  lighten-5',
        'mt-2 red    lighten-5',
        'mt-2 green  lighten-5',
        'mt-2 green  lighten-4',
        'mt-2 yellow lighten-4',
        'mt-2 green  lighten-4',
        'mt-2 yellow lighten-4',
        'mt-2 green  lighten-4',
        'mt-2 yellow lighten-4',
        'mt-2 green  lighten-4',
        'mt-2 blue   lighten-4',
        'mt-2 green  lighten-4',
        'mt-2 yellow lighten-4',
        'mt-2 green  lighten-4',
        'mt-2 yellow lighten-4',
        'mt-2 green  lighten-4',
        'mt-2 red    lighten-4',
        'mt-2 blue   lighten-4',
      ]
    }
  },
  watch: {
    person() {
      this.apiParams.forEach(params => params.person = this.person)
    },
    fy() {
      this.apiParams.forEach(params => params.fy = this.fy)
    }
  },
  methods: {
    rowClassFn (row) {
      return Object.values(row)[0] == 'Total' ? 'font-weight-bold' : '';
    }
  }
};
</script>
